import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import jsPDF from 'jspdf';
import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CurrencyPipe] 
})
export class AppComponent {
  form: FormGroup;
  prefijo = ""
  numero: number = 0   
  Subtotal = 0
  Descuento = 0
  iva = 0
  Total = 0

  displayedColumns: string[] = ['descripcion', 'lote', 'precio', 'cantidad', 'iva', 'subtotal'];
  dataSource: any[] = [];

  fecha = new Date();
 
  fechaFormateada = ``;


  
  constructor(private fb: FormBuilder, private currency:CurrencyPipe ) {
    let dia   = String(this.fecha.getDate()).padStart(2, '0');
    let mes   = String(this.fecha.getMonth() + 1).padStart(2, '0');
    let año   =  this.fecha.getFullYear();
    this.fechaFormateada = dia + '/' + mes + '/' + año;


    this.form = this.fb.group({
      descripcion: ['', Validators.required],
      lote: ['', Validators.required],
      precio: [1, [Validators.required, Validators.min(0)]],
      cantidad: [1, [Validators.required, Validators.min(1)]],
      iva: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formValue = this.form.value;
      const subtotal = (formValue.precio * formValue.cantidad) + ((formValue.precio * formValue.iva / 100) * formValue.cantidad);
      this.dataSource.push({
        ...formValue,
        subtotal: subtotal.toFixed(2)
      });
      this.form.reset();
    }
    this.Calcular()
  }

  Calcular(){

    for(let x of this.dataSource){
      this.Subtotal += parseFloat(x.subtotal);
      this.Descuento += 0;
      this.iva += parseFloat(x.subtotal) * x.iva/100;
      this.Total += parseFloat(x.subtotal+(x.subtotal * x.iva/100));
    }

    this.Subtotal = this.dataSource.reduce((acc, curr) => acc + parseFloat(curr.subtotal), 0);
    this.Descuento = 0;


    this.Total = this.Subtotal - this.Descuento + this.iva;
  }

  generateInvoice() {

    const props = {
      outputType: OutputType.Save,
      returnJsPDFDocObject: true,
      fileName: "Invoice 2021",
      orientationLandscape: false,
      compress: true,
      logo: {
        src: "https://i.postimg.cc/W3xmNT5M/Dm-2.png",
        type: 'PNG',
        width: 50,
        height: 50,
        margin: { top: -10, left: 0 }
      },
      stamp: {
        inAllPages: true,
        src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
        type: 'JPG',
        width: 20,
        height: 20,
        margin: { top: 0, left: 0 }
      },
      business: {
        name: "DOCLIMAG",
        address: "CLL 7 N° 6-46 CIENAGA MAG.",
        phone: "(301)3806186",
        email: "info@doclimag.com.co",
        email_1: "12626919-1",
        website: "",
      },
      contact: {
        label: "Facturado a: ",
        name: "SUMIPROD DE LA COSTA S.A.S.",
        address: "CLL 44B N° 21G-11 URB Santa Cruz",
        phone: "(432)-7722",
        email: "sumiprodelacosta@gmail.com",
        otherInfo: "",
      },
      invoice: {

        label: "FACTURA #: "+this.prefijo,
        num: this.numero,
        invGenDate: "Fecha de Vencimiento:"+this.fechaFormateada,
        invDate: "Fecha Factura:"+this.fechaFormateada,
        headerBorder: false,
        tableBodyBorder: false,
        header: [
          { title: "#", style: { width: 10 } },
          { title: "Descripción", style: { width: 30 } },
          { title: "Lote", style: { width: 80 } },
          { title: "Precio" },
          { title: "Cant." },
          { title: "Iva" },
          { title: "Subtotal" }
        ],
        table: Array.from(this.dataSource, (item, index) => ([
          index + 1,
          item.descripcion,
          item.lote,
          this.currency.transform(item.precio),
          this.currency.transform(item.cantidad),
          this.currency.transform(item.iva),
          this.currency.transform(item.subtotal)
      
        ])),
        additionalRows: [
          { col1: 'Subtotal:', col2: this.currency.transform(this.Subtotal)||"0",  style: { fontSize: 14 } },
          { col1: 'Descuento:', col2: this.currency.transform(this.Descuento) ||"0", style: { fontSize: 10 } },
          { col1: 'Iva:', col2:  this.currency.transform(this.iva)||"0", style: { fontSize: 10 } },
          { col1: 'Total:', col2:  this.currency.transform(this.Total)||"0", style: { fontSize: 14 } }
        ],
        invDescLabel: "Notas",
        invDesc: "No requiere protesto ni requerimientos previos; el no pago oportuno genera intereses de mora al máximo legal autorizado. Autorizo a Doclimag. La guía es el único documento válido para reclamaciones de faltantes o fechas de recibo; no se aceptan devoluciones sin previa autorización. El comprador no podrá alegar falta de representación; la factura se considera aceptada si no se presenta un reclamo escrito dentro de los tres (3) días siguientes a su recepción. No se aceptan reclamos después de 72 horas de recibida la mercancía",
      },
      footer: {
        text: "Representación Gráfica De Factura Electrónica De Venta.",
      },
      pageEnable: true,
      pageLabel: "Pagina ",
    };

    var pdfObject:any = jsPDFInvoiceTemplate(props);
    pdfObject.save('invoice.pdf');
  }
}
